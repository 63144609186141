<template>
    <div>
        <SearchForm
            @onReset="resetData"
            @onSearch="filterSearch"
            @onAdd="
                showDialog = true;
                isUpdate = false;
            " />
        <div
            v-loading="loading"
            class="overflow-x-auto bg-white mt-2 rounded-lg"
            element-loading-text="載入中"
            element-loading-spinner="el-icon-loading text-red-600"
            element-loading-customClass="flex justify-center items-center">
            <table class="myTable">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>名稱</th>
                        <th>新增日期</th>
                        <th v-permission="['update']">編輯</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in datas" :key="index" class="text-center duration-200 transition">
                        <td>{{ index + 1 }}</td>
                        <td>{{ $subString(item.name, 10) }}</td>
                        <td>{{ $moment().format("YYYY/MM/DD") }}</td>
                        <td v-permission="['update']" class="cursor-pointer" @click="onEdit(item)">
                            <i class="far fa-edit"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Dialog :showDialog="showDialog">
            <el-form ref="form" :model="form" :rules="rules">
                <div class="bg-white p-3 rounded-lg">
                    <el-form-item prop="name">
                        <el-input v-model="form.name" placeholder="輸入標籤名稱" :maxlength="10"></el-input>
                    </el-form-item>
                    <div class="flex justify-center">
                        <button type="button" class="border border-black text-black px-3 py-0.5 rounded text-sm mr-5" @click.prevent="closeConfirm">
                            取消
                        </button>
                        <button type="submit" class="bg-orange-600 text-white px-3 py-0.5 rounded text-sm" @click.prevent="onSubmit('form')">
                            確認
                        </button>
                    </div>
                </div>
            </el-form>
        </Dialog>
        <div class="justify-center mt-10 flex">
            <MyPagination
                :paginationData="paginationData"
                :currentPage="currentPage"
                @onPageSizeChange="pageSizeChange"
                @onCurrentPageChange="currentPageChange" />
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import SearchForm from "./components/SearchForm.vue";
import Dialog from "@/components/Dialog.vue";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";

// const generateData = () => {
//     const data = [];
//     for (let i = 1; i <= 15; i++) {
//         data.push({
//             key: i,
//             label: `標記選項 ${i}`,
//             disabled: i % 4 === 0,
//         });
//     }
//     return data;
// };
export default {
    components: {
        Dialog,
        MyPagination,
        SearchForm,
    },
    data() {
        return {
            datas: [],
            // 判斷是否顯示彈窗
            showDialog: false,
            // 表單資料
            form: {},
            rules: {
                name: [
                    { required: true, message: "標籤名稱為必填", trigger: "blur" },
                    { max: 10, message: "標籤名稱最長為10個字", trigger: "blur" },
                ],
            },
            // 預設頁面
            currentPage: 0,
            // 分頁資料
            paginationData: {
                limit: 15,
                total: 0,
            },
            loading: false,
            isUpdate: false,
            // 搜尋條件
            filterData: {},
        };
    },
    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
        // 新增
        async add() {
            try {
                await this.$api.AddTagApi(this.form.name);
                this.$message({
                    type: "success",
                    message: "新增成功",
                });
                await this.getLists({});
                this.form = {};
                this.showDialog = false;
            } catch (err) {
                this.errorCallback({ err });
                this.showDialog = false;
            }
        },
        // update
        async update() {
            try {
                await this.$api.UpdateTagApi(this.form.id, this.form.name);
                this.$message({
                    type: "success",
                    message: "更新成功",
                });
                await this.getLists({});
                this.form = {};
                this.showDialog = false;
            } catch (err) {
                this.errorCallback({ err });
                this.showDialog = false;
            }
        },
        /**
         * 更新一頁取得幾筆資料
         * @param { type Number(數字) } val 一頁取得幾筆資料設定值
         */
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            // 清空列表資料
            this.datas = [];
            this.getLists({
                page: this.currentPage,
                limit: this.paginationData.limit,
                ...this.filterData,
            });
        },
        // 清空選擇條件
        resetData() {
            this.filterData = {};
            this.currentPage = 1;
            this.getLists({ limit: this.paginationData.limit });
        },
        /**
         * @param { tpye Object(物件) } filterData 搜尋過濾資料
         */
        filterSearch(filterData) {
            this.filterData = filterData;
            this.getLists({
                ...this.filterData,
                page: 1,
                limit: this.paginationData.limit,
            });
        },
        /**
         * 取得列表資料
         * @param { type Object(物件) } filterData 搜尋條件
         * @param { type Boolean(布林) } isDefaultSorted 判斷是否用預設排序
         */
        async getLists(filterData) {
            this.loading = true;
            // 判斷沒有傳入 sort_type 值時 預設為倒序
            if (!Object.prototype.hasOwnProperty.call(filterData, "sort_type")) {
                filterData["sort_type"] = "desc";
            }
            // 判斷沒有傳入 sort_by 值時 預設為 created_at
            if (!Object.prototype.hasOwnProperty.call(filterData, "sort_by")) {
                filterData["sort_by"] = "created_at";
            }
            try {
                const { data } = await this.$api.GetTagListApi(filterData);
                this.loading = false;
                this.currentPage = data.current_page;
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
                this.datas = data.data;
            } catch (err) {
                console.log(err);
                this.loading = false;
                this.$message({
                    type: "error",
                    message: "取得資料失敗",
                });
            }
        },
        /**
         * 切換分頁
         * @param { type Number(數字) } val 分頁頁碼
         */
        currentPageChange(val) {
            // 設定目前分頁為第幾幾面
            this.currentPage = val;
            // 清空列表資料
            this.datas = [];
            this.getLists({
                page: this.currentPage,
                limit: this.paginationData.limit,
                ...this.filterData,
            });
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                // 判斷為更新時 執行更新方法
                if (this.isUpdate) {
                    this.update();
                    return;
                }
                await this.add();
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 關閉彈窗
        closeConfirm() {
            this.form = {};
            this.isUpdate = false;
            this.showDialog = false;
        },
        /**
         * 編輯資料
         * @param { type Object(物件) } data 標籤資料
         */
        onEdit(data) {
            this.form = { name: data.name, id: data.id };
            this.isUpdate = true;
            this.showDialog = true;
        },
    },
    async created() {
        await this.getLists({});
    },
};
</script>
